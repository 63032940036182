<template>
  <div class="action-block if-else-block">
    <div class="common-action green" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }}
    </div>

    <vuestic-modal :isOpen="isShowModal" @cancel="isShowModal = false" @ok="save" :cancelShown="false" :force="true"
      okText="Save" large fixed>
      <span slot="title" class="text-primary font-weight-bold">{{ block.name }}</span>
      <text-input v-model="block.name" label="Label" />
      <div v-if="!branch.none" v-for="(branch, index) in block.branches" :key="`branch-${index}`" class="branch">
        <div class="row">
          <div class="col-md-6">
            <text-input v-model="branch.name" placeholder="Branch Name" label="Branch Name"
              tooltip="Enter name of this specific condition/logic" />
          </div>
          <div class="col-md-6 text-right">
            <button v-if="!branch.none && block.branches.length > 2" class="btn btn-sm btn-danger mt-2" @click="handleRemoveBranch(index)">
              <i class="fa fa-trash"></i> Delete
            </button>
          </div>
        </div>
        <div v-if="branch.none" class="row">
          <div class="col-md-12">
          </div>
        </div>
        <div v-for="(segment, segmentIndex) in branch.segments" :key="`segment-${segmentIndex}`" class="row">
          <div class="col-md-12">
            <div v-for="(condition, conditionIndex) in segment.conditions" :key="`condition-${conditionIndex}`"
              class="row mt-2 mb-2">
              <div class="col-md-4">
                <div class="control-label">
                  Input
                </div>
                <select v-model="condition.condition" class="form-control">
                  <option value="SMS">Contact Reply</option>
                </select>
              </div>
              <div class="col-md-3">
                <div class="control-label">
                  Operator
                  <a v-b-tooltip.hover title="Select condition logic from drop-down"
                    class="fa fa-question-circle tooltip-icon" />
                </div>
                <select v-model="condition.operator" class="form-control">
                  <option v-for="operator in operators" :key="operator.value" :value="operator.value"
                    :selected="condition.operator === operator.value">{{ operator.label }}</option>
                </select>
              </div>
              <div class="col-md-5 d-flex align-items-center justify-content-between">
                <text-input v-model="condition.statement" :margin-bottom="0" label="Value"
                  tooltip="Type in condition to make the formula/logic true" style="flex: 1 0 auto;" />
                <button class="btn btn-sm btn-danger ml-2"
                  @click="handleRemoveCondition(index, segmentIndex, conditionIndex)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
              <div v-if="conditionIndex < segment.conditions.length - 1" class="col-md-12 mt-1">
                <span class="pl-1 font-weight-bold">{{ segment.logic === 'And' ? 'AND' : 'OR' }}</span>
              </div>
            </div>

            <div class="col-md-12 text-right">
              <button v-if="segment.conditions" class="btn btn-sm btn-primary mt-2" @click="addCondition(segment)">
                <i class="fa fa-plus"></i> Add Condition
              </button>
            </div>
          </div>

          <div v-if="segment.conditions && segment.conditions.length > 1" class="col-md-12 mt-1 mb-4">
            <button class="btn btn-sm"
              :class="{ 'btn-primary': segment.logic === 'And', 'btn-outline-primary': segment.logic !== 'And' }"
              @click="handleSetLogic(segment, 'And')">
              AND
            </button>
            <button class="btn btn-sm ml-2"
              :class="{ 'btn-primary': segment.logic === 'Or', 'btn-outline-primary': segment.logic !== 'Or' }"
              @click="handleSetLogic(segment, 'Or')">
              OR
            </button>
          </div>
        </div>
      </div>
      <button class="btn btn-primary" @click="addbranch">
        <i class="uil uil-plus"></i> Add branch
      </button>
    </vuestic-modal>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      selectedBranchId: null,
      selectedConditionId: null,
      operators: [
        { label: 'Is', value: 'Is' },
        { label: 'Is not', value: 'IsNot' },
        { label: 'Contains', value: 'Contains' },
        { label: 'Does not contain', value: 'DoesNotContain' },
        { label: 'Is not empty', value: 'IsNotEmpty' },
        { label: 'Is empty', value: 'IsEmpty' },
      ]
    }
  },

  computed: {
    durationOptions() {
      return new Array(60)
    }
  },

  mounted() {
    if (this.block.isNew) {
      this.block.isNew = false
      this.addbranch()
      this.showModal()
    }
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },

    onOpenSelect(branchId, conditionId) {
      this.selectedBranchId = branchId
      this.selectedConditionId = conditionId
    },

    addStatment(content) {
      if (this.block.branches[this.selectedBranchId] && this.block.branches[this.selectedBranchId].conditions[this.selectedConditionId]) {
        if (!this.block.branches[this.selectedBranchId].conditions[this.selectedConditionId].statements) {
          this.block.branches[this.selectedBranchId].conditions[this.selectedConditionId].statements = []
        }
        this.block.branches[this.selectedBranchId].conditions[this.selectedConditionId].statements.push(content)
      }
    },

    addbranch() {
      this.block.branches.push({
        name: 'Branch',
        logic: 'And',
        segments: [
          {
            logic: 'Or',
            conditions: [
              {
                condition: 'SMS',
                operator: 'Is',
                statement: null,
              }
            ]
          }
        ]
      })
    },

    addCondition(segment) {
      segment.conditions.push({
        condition: 'SMS',
        operator: 'Is',
        statement: null,
        logic: 'Or',
      })
    },

    handleSetLogic(segment, logic) {
      segment.logic = logic
    },

    handleRemoveBranch (index) {
      this.block.branches.splice(index, 1)
      this.$emit('removed-branch', { block: this.block, index: index })
    },

    handleRemoveCondition(index, segmentIndex, conditionindex) {
      if (this.block.branches[index] && this.block.branches[index].segments && this.block.branches[index].segments[segmentIndex]) {
        this.block.branches[index].segments[segmentIndex].conditions.splice(conditionindex, 1)
      }
    },
  },
}
</script>
    
<style lang="scss" scoped>
.branch {
  position: relative;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  border: 2px solid #ebebeb;
}

select {
  width: 100%;
  background: none !important;
  border: .0625rem solid #e7e7e7;
  border-radius: .3rem;
}

div.control-label {
  font-size: 0.6rem;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;

  .tooltip-icon {
    font-size: 16px;
  }
}</style>
  