<template>
  <div v-if="isReady" class="block-content-column">
    <ConditionBranch v-if="branch" :branch="branch">
      <NewActionBlock :index="0" :level="level" :actionType="actionType" @onActionConfirm="onActionConfirm"
        @selected="addBlock" />
    </ConditionBranch>

    <vuestic-modal :isOpen="isShowDeleteModal" :hideDefaultActions="true" @cancel="() => isShowDeleteModal = false"
      okText="Delete" :cancelShown="false" @ok="handleDeleteBlock" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger font-weight-bold">Delete Block<span v-if="isActionAll">s Below</span></span>
      <div v-if="selectedBlock">
        <p class="font-weight-bold" v-if="isActionAll">Are you sure you want to delete this action <strong>"{{
          selectedBlock.name }}"</strong> and all the actions below it?</p>
        <p class="font-weight-bold" v-else>Are you sure you want to delete block <strong>"{{ selectedBlock.name
        }}"</strong> ?</p>
        <span>This step<span v-if="isActionAll"> and all the below it</span> will be deleted. These changes will only take
          effect once you save this automation.</span>
      </div>
    </vuestic-modal>

    <div v-for="(block, index) in filteredBlocks" :key="`block-${index}`" class="block-item"
      :class="{ 'last-item': isLastItem(block, index), 'active': openValue[index] }">
      <div class="block-content">
        <div class="block-controls action">
          <VuesticPopover popoverClass="custom-dropdown block-controls-action" placement="bottom" :auto-hide="true"
            :open="openValue[index]" @onUpdateOpen="(val) => onUpdateOpen(index, val)">
            <span slot="trigger" class="trigger-span">
              <span class="btn-action" title="Delete">
                <i class="fa fa-bars"></i>
              </span>
            </span>
            <span slot="body">
              <div class="header-wrapper">Actions</div>
              <div class="content-wrapper">
                <ul>
                  <li @click="() => onClickBlockActionItem(index, block, 'copy')">Copy Action <i class="fa fa-clone"></i>
                  </li>
                  <li @click="() => onClickBlockActionItem(index, block, 'copyAll')">Copy All Actions From Here<i
                      class="fa fa-clone"></i></li>
                  <li @click="() => onClickBlockActionItem(index, block, 'move')">Move Action <i class="fa fa-arrows"></i>
                  </li>
                  <li @click="() => onClickBlockActionItem(index, block, 'moveAll')">Move All Actions From Here <i
                      class="fa fa-arrows"></i></li>
                  <li @click="() => onClickBlockActionItem(index, block, 'delete')" class="text-danger">Delete Action <i
                      class="fa fa-trash-o"></i></li>
                  <li @click="() => onClickBlockActionItem(index, block, 'deleteAll')" class="text-danger">Delete All
                    Actions From Here <i class="fa fa-trash-o"></i></li>
                </ul>
              </div>
            </span>
          </VuesticPopover>
        </div>
        <component :is="block.action" :block="block" :blocks="blocks" :wait-contacts="waitContacts" 
          @removed-branch="handleRemovedBranch"/>
        <NewActionBlock v-if="block.action !== 'Condition'" :index="index + 1" :level="level" :actionType="actionType"
          @onActionConfirm="onActionConfirm" @selected="addBlock" />
      </div>
    </div>
    <template v-if="lastBlock && lastBlock.action === 'Condition'">
      <div class="condition-lines">
        <svg preserveAspectRatio="none" viewBox="0 0 800 1">
          <path v-for="(item, index) in lastBlock.branches" :key="`line-${level}-${index + 1}`"
            :d="`M 400 0 L 400 0 ${((index + 1) / lastBlock.branches.length * 800 - 800 / 2 / lastBlock.branches.length)} 0`"
            pointer-events="visibleStroke" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#9298ad"
            stroke-width="1"></path>
        </svg>
      </div>
      <div class="child-column">
        <ListBlock v-for="(item, subIndex) in lastBlock.branches" :key="`branch-${level}-${subIndex + 1}`"
          :blocks="blocks" :level="`${level}-${subIndex + 1}`" :automation="automation" :deep="deep + 1" :branch="item"
          :actionType="actionType" @onActionClick="onActionClick" @onActionConfirm="onActionConfirm" />
      </div>
    </template>
  </div>
</template>
    
<script>
import Vue from 'vue';
import Wait from './WorkFlows/Wait'
import ProcessBetween from './WorkFlows/ProcessBetween'
import SendMessage from './Actions/SendMessage'
import SendCall from './Actions/SendCall'
import SendVoiceMail from './Actions/SendVoiceMail'
import SendEmail from './Actions/SendEmail'
import AddTag from './Actions/AddTag'
import CollectContactData from './Actions/CollectContactData'
import Webhook from './Actions/Webhook'
import Condition from './Actions/Condition'
import NewActionBlock from './NewActionBlock'
import ConditionBranch from './Actions/ConditionBranch'
import AddToAutomation from './Actions/AddToAutomation'
import Alert from './Actions/Alert'

import VuesticPopover from '../../../../vuestic-theme/vuestic-components/vuestic-popover/VuesticPopover';

export default {
  name: 'ListBlock',

  components: {
    NewActionBlock, Wait, SendMessage, SendCall, SendVoiceMail, AddTag,
    SendEmail, CollectContactData, ProcessBetween, Webhook, Condition,
    ConditionBranch, VuesticPopover, AddToAutomation, Alert
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },

    blocks: {
      type: Array,
      default: () => {
        return []
      }
    },

    level: {
      type: String,
      default: "1"
    },

    isShowYes: {
      type: Boolean,
      default: false,
    },

    isShowNo: {
      type: Boolean,
      default: false,
    },

    deep: {
      type: Number,
      default: 0,
    },

    branch: {
      type: Object,
      default: null,
    },

    actionType: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      isReady: false,
      waitContacts: null,
      selectedBlockIndex: null,
      isShowDeleteModal: false,
      selectedBlock: null,
      openValue: {},
      isActionAll: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    isActiveMms() {
      return this.user && this.user.business && this.user.business.is_active_mms
    },

    filteredBlocks() {
      return this.blocks.filter(b => !b.level || b.level === this.level)
    },

    lastBlock() {
      return this.blocks.filter(b => !b.level || b.level === this.level).pop()
    },
    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },
  },

  mounted() {
    // if (this.automation.id) {
    // this.getWaitContacts()
    // }
    this.filteredBlocks.forEach((item, index) => {
      Vue.set(this.openValue, index, false)
    });
    this.isReady = true
  },

  methods: {
    // getWaitContacts() {
    // this.$store
    //   .dispatch('automation/getWaitContacts', this.automation.id)
    //   .then((totalContacts) => {
    //     this.waitContacts = totalContacts
    //   })
    //   .catch(() => {
    //   })
    // },

    onClickBlockActionItem(index, block, type) {
      Vue.set(this.openValue, index, false)
      this.isActionAll = false;
      this.$emit('onActionClick', {
        type,
        block,
        index
      })
      
      switch (type) {
        case 'delete':
          this.deleteBlock(index, block)
          break;
        case 'deleteAll':
          this.isActionAll = true
          this.deleteBlock(index, block)
          break;
        default:
          break;
      }
    },

    onActionClick(data) {
      this.$emit('onActionClick', data)
    },

    onUpdateOpen(index, val) {
      Vue.set(this.openValue, index, val)
    },

    isLastItem(block, index) {
      return index === this.filteredBlocks.length - 1 && block.action !== 'Condition'
    },

    onActionConfirm(data) {
      this.$emit('onActionConfirm', data)
    },

    addBlock(args) {
      let newBlock = JSON.parse(JSON.stringify(args.action))
      newBlock.isNew = true
      newBlock.level = args.level

      if(args.action.action === 'CollectContactData') {
        this.contactFields.forEach(function(item) {
          let format = item.type === 3 ? 'mm/dd' : ''
          newBlock.options.collectFields.push({name: item.id, type: item.type, format, label: item.name, enable: false, message: `Please reply with your ${ item.name}`});
        });
      }


      let levelBlocks = this.automation.blocks.filter(b => b.level === args.level)

      if (args.index === -1) {
        this.automation.blocks = [newBlock, ...this.automation.blocks]
      } else {
        if (args.index === 0) {
          this.automation.blocks = [
            newBlock, ...levelBlocks,
            ...this.automation.blocks.filter(b => b.level !== args.level)
          ]
        } else {
          this.automation.blocks = [
            ...(levelBlocks.slice(0, args.index)), newBlock, ...levelBlocks.slice(args.index, levelBlocks.length),
            ...this.automation.blocks.filter(b => b.level !== args.level)
          ]
        }
      }

      if (args.action.action === 'Condition') {
        for (let i = args.index + 1; i < this.automation.blocks.length; i++) {
          if (this.automation.blocks[i].level.match(`^${args.level}`)) {
            this.automation.blocks[i].level = `${args.level}-2${this.automation.blocks[i].level.replace(new RegExp(`^${args.level}`), '')}`
          }
        }
      }
    },

    copyBlock(index, block) {
      this.selectedBlock = block
      this.selectedBlockIndex = index
    },

    deleteBlock(index, block) {
      this.isShowDeleteModal = true
      this.selectedBlock = block
      this.selectedBlockIndex = index
    },

    handleDeleteBlock() {
      this.isShowDeleteModal = false

      let levelBlocks = this.automation.blocks.filter(b => b.level === this.selectedBlock.level)
      if (this.isActionAll) {
        levelBlocks = levelBlocks.slice(0, this.selectedBlockIndex)
        this.automation.blocks = [
          ...this.automation.blocks.filter(b => !b.level.startsWith(this.selectedBlock.level)),
          ...levelBlocks
        ]
      } else {
        levelBlocks.splice(this.selectedBlockIndex, 1)
        this.automation.blocks = [
          ...this.automation.blocks.filter(b => b.level !== this.selectedBlock.level),
          ...levelBlocks
        ]
      }

      if (this.selectedBlock.action === 'Condition') {
        this.automation.blocks = this.automation.blocks.filter(b => !b.level.match(`^${this.selectedBlock.level}-`))
      }
    },

    handleRemovedBranch (args) {
      let level = `${args.block.level}-${args.index + 1}`
      let blocks = this.automation.blocks.filter(b => !b.level.startsWith(level))

      if (args.block.branches[args.index]) {
        for (let i = args.index; i < args.block.branches.length; i++) {
          let level = `${args.block.level}-${i + 2}`

          blocks = blocks.map(block => {
            if (block.level.startsWith(level)) {
              block.level = block.level.replace(new RegExp(`^${level}`), `${args.block.level}-${i + 1}`)
            }

            return block
          })
        }
      }

      this.automation.blocks = blocks
    },
  },
}
</script>

<style lang="scss">
.block-controls-action {
  top: 10px !important;

  .trigger-span {
    cursor: pointer;
    color: inherit;
    font-weight: bold;
    display: inline-block;

    span.arrow-span {
      padding-left: 8px;
    }
  }

  .wrapper {
    min-width: 150px;
    padding: 0;

    .tooltip-inner {
      background-color: $white;
      color: $darkest-gray;
      padding: 0;

      .popover-header,
      .popover-body {
        color: inherit;
        background-color: inherit;
      }

      .popover-body {
        text-align: left;
        padding: 0;

        div.header-wrapper {
          padding: 10px 15px;
          background-color: #ddd;
          color: #000;
          border-top-left-radius: 6px;
          border-top-right-radius: 5px;
        }

        div.content-wrapper {
          display: flex;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 250px;

            li {
              cursor: pointer;
              padding: 8px 15px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:hover {
                background-color: #eee;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.editor {
  margin: 50px auto 0;
  max-width: 600px;
}

.block-item.condition {
  padding-top: 0px;

  &:before {
    content: " ";
    width: 8px;
    height: 8px;
    position: absolute;
    background: #9298ad;
    border-radius: 50%;
    left: calc(50% - 4px);
    top: -4px;
    z-index: 2;
  }
}

.condition-lines {
  height: 60px;
  position: relative;
  margin-top: 2px;
  margin-bottom: 20px;

  svg {
    position: absolute;
    z-index: 50;
  }
}

svg {
  fill: #9298ad;

  path {
    stroke-width: 2px;
    fill: none;
    stroke: #9298ad;
  }
}

.plumbing {
  display: inline-block;
  width: 158px;
  height: 70px;
}

.if-branches {
  display: flex;
  align-items: center;
  justify-content: center;

  .if-yes-branch,
  .if-no-branch {
    width: 50%;
    justify-content: center;
    display: flex;
  }

  .if-yes {
    border: 2px solid #11866f;
    color: #11866f;
  }

  .if-no {
    border: 2px solid #e40e49;
    color: #e40e49;
  }

  .plumbing {
    position: absolute;
  }
}

.blocknode-path-label {
  position: relative;
  text-align: center;
  margin: 0 0 20px 0;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  background: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
}</style>