<template>
<div class="action-block">
  <div class="common-action dark-gray" @click="showModal">
    <i :class="block.icon"></i> {{  block.name  }}
  </div>

  <vuestic-modal
    :isOpen="isShowModal"
    @cancel="isShowModal = false"
    :okShown="false"
    :cancelShown="false"
    :force="true"
    cancelClass="btn btn-danger"
  >
    <span slot="title" class="text-primary font-weight-bold">{{ block.description }}</span>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="webhookActionForm"
    >
      <form @submit.prevent="handleSubmit(save)" class="webhookActionForm">
        <div class="row">
          <div class="col-12 mb-4">
            <text-input v-model="block.name"></text-input>
          </div>
          <div class="col-md-12">
            <text-input
              name="URL"
              validate="required|url"
              v-model="block.options.url"
              label="Webhook URL"
              tooltip="URL where we will send a contact data to"
            />
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <p class="text-center mb-4 font-weight-bold">Ready to send. We'll post a contact data to url above.</p>
        </div>
        <div class="col-md-12 mt-4" style="font-weight: 600;">
          <p>Contact Payload: </p>
<pre class="payload-wrapper">
{
  "contact" => [
    "number" => "string",
    "type" => "string",
    "first_name" => "string",
    "last_name" => "string",
    "email" => "string",
    "bday" => "date(e.g 1995-12-24)",
    "anniversary" => "date(e.g 1995-12-24)",
    "note" => "string",
  ],
  "install" => [
    "number" => "string",
    "website" => "string",
    "marketing_source" => "string",
  ],
  "department" => [
    "name" => "string"
  ],
  "tags" => "array"
}</pre>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="invalid || processing || !allowSave" style="min-width: 80px;">
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>
</div>
</template>

<script>
import TagSelector from '../../../../common/TagSelector'

export default {
  components: { TagSelector },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      loading: false,
      processing: false,
      isShowModal: false,
    }
  },

  mounted() {
    if (this.block.isNew) {
      this.block.isNew = false
      this.showModal()
    }
  },

  computed: {
    allowSave() {
      return this.block.options.url
    }
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },
  }
}
</script>

<style lang="scss" scoped>
.payload-wrapper {
  background: #e0e0e0;
  padding: 10px;
}
</style>