<template>
<div class="action-block">
  <div class="common-action dark-gray" @click="showModal">
    <i :class="block.icon"></i> {{  block.name  }}
  </div>

  <vuestic-modal
    :isOpen="isShowModal"
    @cancel="isShowModal = false"
    :okShown="false"
    :cancelShown="false"
    :force="true"
    cancelClass="btn btn-danger"
  >
    <span slot="title" class="text-primary font-weight-bold">{{ block.description }}</span>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="sendMessageActionForm"
    >
      <form @submit.prevent="handleSubmit(save)" class="sendMessageActionForm mt-2">
        <div class="row">
          <div class="col-12 mb-4">
            <text-input v-model="block.name"></text-input>
          </div>
          <div class="col-md-12">
            <span><label class="input-label">Call Whisper Text </label> <a herf="javascript:;" v-b-tooltip.hover title="This action will trigger a call to you and then connect you to your prospect. The content you enter below will alert you of where the call originated from." class="fa fa-question-circle tooltip-icon pl-1" /></span>
            <validation-provider
              rules="required"
              v-slot="{ }"
              name="Message"
            >
              <TextareaEmojiPicker
                v-model="block.options.whisper"
                :focused="true"
                :rows="7"
                :enabledMms="false"
                ref="textareaEmoji"
                :maxLength="maxLength"
                :showSendButton="false"
                :hasHoverEffect="false"
                :optText="optText"
              />
            </validation-provider>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <span>Save</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>
</div>
</template>

<script>
import SMSLength from '../../../../common/SMSLength'
import TextareaEmojiPicker from "../../../../common/TextareaEmojiPicker";
export default {
  components: { SMSLength, TextareaEmojiPicker },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      processing: false,
      isShowModal: false,
    }
  },

  mounted() {
    if (this.block.isNew) {
      this.block.isNew = false
      this.showModal()
    }
  },

  computed: {
    optText() {
      return this.$store.getters["auth/optText"];
    },

    maxLength() {
      return 1600 - this.optText.length;
    },
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },
  }
}
</script>

<style lang="scss" scoped>
</style>