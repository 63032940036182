<template>
  <div class="action-block">
    <div class="common-action dark-gray" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }}
    </div>

    <vuestic-modal :isOpen="isShowModal" @cancel="isShowModal = false" :okShown="false" :cancelShown="false" :force="true"
      cancelClass="btn btn-danger">
      <span slot="title" class="text-primary font-weight-bold">{{ block.description }}</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="sendMessageActionForm">
        <form @submit.prevent="handleSubmit(save)" class="sendMessageActionForm">
          <div class="row">
            <div class="col-12 mb-4">
              <text-input v-model="block.name"></text-input>
            </div>
            <div class="col-md-12">
              <validation-provider rules="required" v-slot="{}" name="Message">
                <TextareaEmojiPicker v-model="block.options.content" :focused="true" :rows="7" :enabledMms="true"
                  ref="textareaEmoji" :maxLength="maxLength" :showSendButton="false" :hasHoverEffect="false"
                  :optText="optText" :images="block.options.images" :enable-ai="true" @done="handleUploadedImage" />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button type="button" class="btn btn-primary mr-4" :disabled="invalid || processing" @click="onClickTest">
                <span>Test SMS</span>
              </button>
              <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
                <span>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalTest" @ok="handleTest" @cancel="isOpenModalTest = false" okText="Send"
      cancelText="Cancel" :closeOnOk="false" :processing="processing" okClass="btn btn-primary"
      :okDisabled="!testNumber">
      <span slot="title" class="text-primary font-weight-bold">Test SMS</span>
      <p class="font-weight-bold">Please Input Phone Number to Send Test SMS.</p>
      <p>Opt-out message is only appened to the very first message sent out from our system.</p>
      <phone-number-input name="Phone Number" v-model="testNumber" label="Phone Number" />
    </vuestic-modal>
  </div>
</template>

<script>
import SMSLength from '../../../../common/SMSLength'
import TextareaEmojiPicker from "../../../../common/TextareaEmojiPicker";
export default {
  components: { SMSLength, TextareaEmojiPicker },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      processing: false,
      isShowModal: false,
      isOpenModalTest: false,
      testNumber: null,
    }
  },

  mounted() {
    if (this.block.isNew) {
      this.block.isNew = false
      this.showModal()
    }
  },

  computed: {
    optText() {
      return this.$store.getters["auth/optText"];
    },

    maxLength() {
      return 1600 - (this.optText? this.optText.length: 0);
    },
  },

  methods: {
    handleUploadedImage(images) {
      this.block.options.images = images
    },

    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },

    onClickTest() {
      this.isOpenModalTest = true
    },

    handleTest() {
      this.processing = true
      const param = Vue.util.extend({}, this.block.options)
      param.number = this.testNumber

      this.$store.dispatch('automation/testSms', param).then((res) => {
        this.isOpenModalTest = false
        this.processing = false
        this.testNumber = null
      })
      .catch(() => {
        this.processing = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>